<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area pt--120 pb--190 bg_image donto-bg"
      data-black-overlay="6"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title" style="color:#fff;">Donto</h2>
              <p>
                
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <div class="rn-service-details ptb--120 bg_color--1">
            <v-container>
              <v-row>
                <v-col lg="12">
                  <div class="container">
              <v-row>
                <v-col lg="12">
                  <div class="text-center section-title mb--30 mb_sm--0">
                    <span class="subtitle">Our service</span>
                    <h2 class="heading-title">Donto REST API</h2>
                    <p>
                      Donto REST API는 텍스트 분석을 위한 주요 기능들을 제공하는 서비스로, 사용자 정의 사전, 개체명 인식, 형태소 분석 등을 포함합니다. 이 API는 다양한 프로그래밍 환경에서 쉽게 통합될 수 있는 RESTful 구조로 되어 있어, 특수 용어 처리와 중요 정보의 식별을 간편하게 해줍니다. 이를 통해 데이터 분석가와 개발자는 복잡한 데이터 세트를 보다 깊이 있고 효율적으로 분석할 수 있게 됩니다.
                    </p>
                  </div>
                </v-col>
              </v-row>
              <ServieThree />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    
    <Footer />
  </div>
</template>

<script>
  import feather from "feather-icons";
  import Header from "../../components/header/Header";
  import Footer from "../../components/footer/Footer";
    import ServieThree from "../../components/service/ServiceThree";
  
  export default {
    components: {
      Header,
      Footer,
      ServieThree,
    },
    data() {
      return {
        logo: require("../../assets/images/logo/logo.png"),
        items: [
          {
            thumb: require("../../assets/images/product/dstocks_site.png"),
            src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
          },
        ],
        index: null,
        listOne: [
          {
            id: 1,
            icon: "check",
            desc: `5 PPC Campaigns the majority.`,
          },
          {
            id: 2,
            icon: "check",
            desc: `Digital Marketing going to.`,
          },
          {
            id: 3,
            icon: "check",
            desc: ` Marketing Agency passage of.`,
          },
          {
            id: 4,
            icon: "check",
            desc: ` Seo Friendly you are going.`,
          },
          {
            id: 5,
            icon: "check",
            desc: `5 PPC Campaigns the majority.`,
          },
          {
            id: 6,
            icon: "check",
            desc: `Fast-Track Your business`,
          },
        ],
      };
    },

    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
