<template>
  <v-row class=" service-main-wrapper">
    <!-- Start Single Service  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      v-for="(service, i) in serviceContent"
      :key="i"
    >
      <div class="service service__style--2 text-left bg-gray">
        <router-link to="#">
          <div class="service">
            <div class="icon">
              <div v-html="iconSvg(service.icon)"></div>
            </div>
            <div class="content">
              <h3 class="heading-title">{{ service.title }}</h3>
              <p>
                {{ service.desc }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        serviceContent: [
          {
            icon: "database",
            title: "공통사전 API",
            desc: `프로젝트별 공통사전 관리`,
          },
          {
            icon: "database",
            title: "사용자사전 API",
            desc: `사용자별 신조어, 다중어 관리 `,
          },
          {
            icon: "database",
            title: "불용어사전 API",
            desc: `불필요한 텍스트 및 품사 제거`,
          },
          {
            icon: "database",
            title: "동의어사전 API",
            desc: `원형 및 의미가 비슷한 단어 인식`,
          },
          {
            icon: "database",
            title: "개체명사전 API",
            desc: `특정 단어 범주화`,
          },
          {
            icon: "database",
            title: "형태소분석 API",
            desc: `형태소 품사 식별`,
          },
        ],
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
